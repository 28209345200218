#body,
html,
body {
  overflow-x: hidden;
  /*background-color: #eee;*/
  height: auto !important;
  font-family: "Poppins", sans-serif !important;
}

a {
  text-decoration: none;
}

iframe {
  z-index: -1 !important;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 13px 5px 13px;
  font-size: 15px;
  font-weight: 700;
  white-space: nowrap;
  transition: 0.3s;
}

ul.top__menu a.first_link,
ul.top__menu a.first_link > span {
  color: #fff !important;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #47b2e4;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  border: 2px solid #47b2e4;
  font-weight: 700;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #31a9e1;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  /*padding: 10px 20px;*/
  padding: 4px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 700;
  color: #0c3c53;
  border-bottom: 1px dashed #efefef;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #47b2e4;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  /*.navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }*/
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;

  margin-left: auto;
  margin-right: 14px;
}

.mobile-nav-toggle.fa {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }
  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(40, 58, 90, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #37517e;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #47b2e4;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
  color: #37517e;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #47b2e4;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

.ed-top {
  background-image: linear-gradient(45deg, #111111, #030404a6);
}

.ps__top__menu__sticky {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9;
}

.ps__top__menu {
  /*position: sticky;
    top: 0;
    z-index: 9;*/
  /*background-color: #07375c;*/
  background-color: #432971;
}

.wed-hom-footer {
  padding-top: 0px;
  padding-bottom: 0px;
  background-image: linear-gradient(45deg, #e3e3e3, #e3e3e3);
  /* background-image: url(../counter-bg.jpg); */
}

.wed-hom-slider {
  background-image: url(../cta-bg.jpg);
}

.layout-tiru {
  background-size: cover;
  background-position: center top;
  /* background-image: url(../cta-bg.jpg); */
  background-attachment: fixed;
  /* margin-left: 28%; */
}

.wed-foot-link ul {
  padding-left: 0px;
}

.wed-foot-link ul li {
  list-style: none;
  padding-bottom: 8px;
}

.wed-foot-link ul li a {
  font-size: 14px;
  color: #0e0e0e;
}

.vision_box {
  border-radius: 8px;
  border-top: 5px solid #e73790a6;
  background-color: #fcfeff;
  font-size: 15px;
}
.core_purpose_box {
  border-radius: 5px;
  border-top: 5px solid #37e74d9c;
  background-color: #fcfeff;
  font-size: 15px;
}

.mission_box {
  border-radius: 8px;
  border-top: 5px solid #5a37e7a6;
  background-color: #fcfeff;
  font-size: 15px;
}
.ps_home_announ {
  padding: 20px;
  border-radius: 8px;

  border-top: 5px solid #e78837a6 !important;
  background-color: #fcfeff;
}
.ps_why_us_box {
  border-radius: 30px;
  border: 5px solid #055786a6;
  background-color: #fcfeff;
}
.vision_box .card-body,
.mission_box .card-body {
  text-align: justify;
  line-height: 25px;
}

.rowest {
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(7 * var(--bs-gutter-x));

  /* --bs-gutter-x: 1.5rem; */
}

.sec-title {
  font-size: 30px;
  line-height: 24px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.ps__slider__quicklinks {
  background-image: linear-gradient(139deg, #0586ad, #d51892bf);
}

.ps__slider__quicklinks a {
  color: #fff;
  padding: 8px;
}

.ps__slider__quicklinks .div {
  border: 1px solid #efefef;
  width: 100%;
}

.announcement_box {
  border-radius: 15px 15px 0 0;
  border-top: 3px solid #0f15cf;
}

.ps__news__ticker {
  height: 280px !important;
}

.ps__news__ticker li > div {
  border-bottom: 1px solid #efefef;
  padding: 10px 0;
}

.ps__up_events_header {
  padding: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  background-color: #ddf3fd38;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-25 {
  font-size: 25px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

.py-8 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.ps__quiclink__slider__img {
  width: 60px;
  margin-bottom: 5px;
}

.slick-slider {
  margin-bottom: -6px;
}

.ps_min_height {
  min-height: calc(100vh - 250px);
}

.pg-inn {
  padding: 30px 15px;
}

.ps__sidebar__links li > a {
  padding-left: 5px;
  color: #000000;
}

.ps__sidebar__links li i {
  font-size: 12px;
  margin-left: 3px;
}

.ed-com-t1-left ul {
  margin-top: -15px;
}
.bg-theme-yellow {
  background-color: #ffca18;
}

.bg-theme-dark {
  background-color: #39393e;
}
.bg-theme-light {
  background-color: #f5f5f5;
}
.social-icons a {
  font-size: 15px;
}
.social-icons a > i {
  font-size: 18px;
}
.social-icons a:focus,
.social-icons a:hover {
  text-decoration: none;
  font-size: 16px !important;
}
.social-icons a:focus > i,
.social-icons a:hover > i {
  font-size: 20px;
}

.est_bg {
  background-image: url("../section_02.png");
  height: 150px;
  background-size: 100%;
  margin-top: 30px;
  border-radius: 20px;
}
.est_bg .text-one {
  font-weight: 800;
  font-size: 45px;
  color: #fff;
  text-align: center;
}
.est_bg .text-two {
  font-size: 15px;
  color: #fff;
  text-align: center;
}

.ps_testimonials > .row > .col {
  min-height: 200px;
}

.theme-bg-light {
  background-color: rgb(67 41 113 / 20%);
}
.theme-bg-dark {
  background-color: #432971;
}

.wed-foot-link ul li {
  width: 100% !important;
}

.ps__home__slider .carousel-item {
  background-image: linear-gradient(45deg, black, #1c786c);
}

#adm_top_marquee p {
  font-size: 15px;
  line-height: 28px;
}

.top_gray_icons a {
  color: #504a4a;
}

@media screen and (max-width: 992px) {
  .head-2 {
    padding: 33px 0px 0px 0 !important;
  }
}
.modal_custom_close_icon_ps {
  position: absolute;
  color: #141212;
  background: #ffffff;
  padding: 1px 10px;
  right: 20px;
  top: 25px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 18px;
}
.card-hover {
  transition: all 0.5s ease;
}
.card-hover:hover {
  transform: scale(1.03);
}

.back-to-top-gallery {
  position: fixed;
  right: 0px;
  bottom: 300px;
  z-index: 9999;
  padding-inline: 10px;
  background-color: #3633e9;
  /*transform: rotate(-90deg);*/
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 27px;
}

.grayscale {
  filter: grayscale(100%);
}

.offcanvas-sm-end ul li:hover {
  text-decoration: underline;
  cursor: pointer;
}

.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}

.site_settings_canvas {
  height: 250px;
  top: 20% !important;
  width: 300px !important;
}
.site_settings_canvas ul {
  margin-left: 0px;
  padding-left: 0px;
}
.site_settings_canvas ul,
.site_settings_canvas ul li {
  list-style: none;
  font-size: 14px;
}

body.font-size-105 h1,
body.font-size-105 h2,
body.font-size-105 h3,
body.font-size-105 div,
body.font-size-105 p,
body.font-size-105 a {
  font-size: 105% !important;
}
body.font-size-108 h1,
body.font-size-108 h2,
body.font-size-108 h3,
body.font-size-108 div,
body.font-size-108 p,
body.font-size-108 a {
  font-size: 108% !important;
}
body.font-size-110 h1,
body.font-size-110 h2,
body.font-size-110 h3,
body.font-size-110 div,
body.font-size-110 p,
body.font-size-110 a {
  font-size: 110% !important;
}
body.font-size-112 h1,
body.font-size-112 h2,
body.font-size-112 h3,
body.font-size-112 div,
body.font-size-112 p,
body.font-size-112 a {
  font-size: 112% !important;
}
body.negative-page,
body.negative-page h1,
body.negative-page h2,
body.negative-page h3,
body.negative-page div,
body.negative-page p,
body.negative-page a {
  filter: invert(1);

  color: yellow !important;
}

.text_unicode {
  font-size: 14px;
}

.lang-li-box {
  border: 1px solid #fff;
  border-radius: 5px;
}

.ps_slider_container {
  width: 100%; /* Set the container width */
  overflow: hidden; /* Hide overflowed content */
  position: relative; /* Positioning context for the image */
}

.ps_slider_container img {
  position: absolute; /* Absolute positioning to control the image positioning */
  left: 50%; /* Center the image horizontally */
  top: -20%;
  transform: translateX(-50%); /* Offset the image by half its width */
}
.footer {
  background-color: #002147;
}
marquee#adm_top_marquee {
  color: #000 !important;
}
marquee#adm_top_marquee {
  a {
    color: #0707f3 !important;
    font-weight: bold;
    font-size: 14px;
  }
}
